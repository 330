'use strict'
const {WixBusinessError} = require('wix-errors')

class ViewerJsonInvalidViewModeParamError extends WixBusinessError {
    constructor(viewMode) {
        super(`Invalid viewMode parameter, received: ${viewMode}`)
        this.name = 'ViewerJsonInvalidViewModeParamError'
    }
}

class ViewerJsonMeshCalculationError extends WixBusinessError {
    constructor(e) {
        super('Failed to calculate mesh', e)
        this.name = 'ViewerJsonMeshCalculationError'
    }
}

module.exports = {
    ViewerJsonInvalidViewModeParamError,
    ViewerJsonMeshCalculationError
}
