'use strict'

const {getMeshResults, structure2mesh, getMasterPageChildrenGroups} = require('./mesh')

module.exports = {
    getMeshResults,
    structure2mesh,
    getMasterPageChildrenGroups,
    removeHash: value => value && value.replace('#', '')
}
