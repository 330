'use strict'
const _ = require('lodash')

module.exports = pageJson => {
    const theme_data = pageJson.data.theme_data

    const refArrays = _.pickBy(theme_data, ['type', 'RefArray'])
    const breakpointRelations = _.pickBy(theme_data, ['type', 'BreakpointRelation'])
    const styleItems = _.pickBy(theme_data, style => style.type !== 'BreakpointRelation' && style.type !== 'RefArray')

    // Map from original styleId to the merged styleId
    const styleIdsMap = Object.assign({}, refArrays, breakpointRelations, ..._(styleItems)
        .groupBy(style => JSON.stringify(_.omit(style, 'id')))
        .map(styles => _(styles).keyBy('id').mapValues(() => _(styles).values().head()).value())
        .value())

    _.forEach(refArrays, refArray => {
        refArray.values = refArray.values.map(value => styleIdsMap[value.id])
    })

    _.forEach(breakpointRelations, relation => {
        relation.ref = styleIdsMap[relation.ref.id]
    })

    // Replace styleId to the merged styleId
    const components = [pageJson.structure]
    let comp = null
    while (components.length) {
        comp = components.shift()
        components.push(...comp.components || [])
        if (comp.styleId) {
            comp.styleId = styleIdsMap[comp.styleId] ? styleIdsMap[comp.styleId].id : comp.styleId
        }
    }

    //eslint-disable-next-line lodash/matches-prop-shorthand,lodash/matches-shorthand
    pageJson.data.theme_data = _.pickBy(styleIdsMap, (style, id) => id === style.id)
}

