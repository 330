'use strict'

const pointers = {
    components: {
        PROPERTY_TYPES: {
            /** @type {'layoutQuery'} */
            LAYOUT_QUERY: 'layoutQuery',
            LAYOUT: 'layout',
            /** @type {'skin'} */
            SKIN: 'skin',
            /** @type {'styleId'} */
            STYLE_ID: 'styleId'
        }
    },
    data: {
        DATA_MAPS: {
            /** @type {'layout_data'} */
            LAYOUT: 'layout_data',
            /** @type {'theme_data'} */
            STYLE: 'theme_data'
        }
    }
}

const MESH_ID_SEPARATOR = '$'

module.exports = {
    pointers,
    MESH_ID_SEPARATOR
}

